import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  role?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
}

const data: IMenuItem[] = [
  {
    icon: 'iconsminds-shop-4',
    label: 'menu-title.dashboard',
    to: `${adminRoot}/dashboard`,
    roles: [UserRole.Admin, UserRole.Editor],
    // role: '/admin/dashboard'
  },
  {
    icon: 'simple-icon-notebook',
    label: 'menu-title.order',
    to: `${adminRoot}/order`,
    subs: [
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.running-order',
        to: `${adminRoot}/order/running-orders`,
        subs: [
          {
            icon: 'simple-icon-basket-loaded',
            label: 'menu-title.orders',
            to: `${adminRoot}/order/running-orders/orders`,
            role: '/admin/orders'
          },
          {
            icon: 'iconsminds-scooter',
            label: 'menu-title.delivery',
            to: `${adminRoot}/order/running-orders/delivery`,
            role: '/admin/deliveries'
          },
          {
            icon: 'simple-icon-user-following',
            label: 'menu-title.dispatch',
            to: `${adminRoot}/order/running-orders/dispatch`,
            role: '/admin/orders'
          }
        ],
      },
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.history',
        to: `${adminRoot}/order/history`,
        subs: [
          {
            icon: 'iconsminds-receipt-4',
            label: 'menu-title.history',
            to: `${adminRoot}/order/history/history-list`,
            role: '/admin/history'
          },
          {
            icon: 'simple-icon-star',
            label: 'menu-title.review',
            to: `${adminRoot}/order/history/reviews`,
          },
          // {
          //   icon: 'simple-icon-credit-card',
          //   label: 'menu-title.cancelled-delivery',
          //   to: `${adminRoot}/order/history/cancelled-delivery`,
          // },
        ],
      },
    ],
  },
  {
    icon: 'iconsminds-map2',
    label: 'menu-title.map-view',
    to: `${adminRoot}/map-views`,
    subs: [
      {
        icon: 'iconsminds-geo2',
        label: 'menu-title.store-location',
        to: `${adminRoot}/map-views/store-location`,
        role: '/admin/store_location'
      },
      {
        icon: 'iconsminds-location-2',
        label: 'menu-title.provider-location',
        to: `${adminRoot}/map-views/provider-location`,
        role: '/admin/provider_location'
      },
      {
        icon: 'iconsminds-map-marker',
        label: 'menu-title.delivery-man-tracking',
        to: `${adminRoot}/map-views/delivery-man-tracking`,
        role: '/admin/location_track'
      }
    ],
  },
  {
    icon: 'iconsminds-receipt-4',
    label: 'menu-title.earning',
    to: `${adminRoot}/earning`,
    subs: [
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.earning',
        to: `${adminRoot}/earning/order`,
        subs: [
          {
            icon: 'simple-icon-basket-loaded',
            label: 'menu-title.order-earning',
            to: `${adminRoot}/earning/order/order-earning`,
            role: '/admin/order_earning'
          },
          {
            icon: 'iconsminds-money-bag',
            label: 'menu-title.deliveryman-weekly',
            to: `${adminRoot}/earning/order/deliveryman-weekly`,
            role: '/admin/provider_weekly_earning'
          },
          {
            icon: 'iconsminds-shop-4',
            label: 'menu-title.store-earning',
            to: `${adminRoot}/earning/order/store-earning`,
            role: '/admin/store_weekly_earning'
          }
        ],
      },
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.wallet',
        to: `${adminRoot}/earning/wallet`,
        subs: [
          {
            icon: 'simple-icon-wallet',
            label: 'menu-title.wallet-history',
            to: `${adminRoot}/earning/wallet/wallet-history`,
            role: '/admin/wallet_detail'
          },
          {
            icon: 'iconsminds-wallet',
            label: 'menu-title.wallet-request',
            to: `${adminRoot}/earning/wallet/wallet-request`,
            role: '/admin/wallet_request'
          },
          {
            icon: 'simple-icon-credit-card',
            label: 'menu-title.transaction-history',
            to: `${adminRoot}/earning/wallet/transaction-history`,
            role: '/admin/transaction_history'
          },
        ],
      },
    ],
  },
  {
    icon: 'iconsminds-pantone',
    label: 'menu-title.menu',
    to: `${adminRoot}/menu`,
    subs: [
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.menu',
        to: `${adminRoot}/menu/levels1`,
        subs: [
          /*{
            icon: 'simple-icon-organization',
            label: 'menu-title.category',
            to: `${adminRoot}/menu/category`,
          },*/
          {
            icon: 'simple-icon-film',
            label: 'menu-title.item',
            to: `${adminRoot}/menu/item-list`,
          },
        ],
      },
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.modifier',
        to: `${adminRoot}/menu/levels2`,
        subs: [
          {
            icon: 'simple-icon-list',
            label: 'menu-title.modifier-group',
            to: `${adminRoot}/menu/modifier-group`,
          }
        ],
      },
    ],
  },
  {
    icon: 'simple-icon-people',
    label: 'menu-title.users',
    to: `${adminRoot}/users`,
    // roles: [UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-people',
        label: 'menu-title.user',
        to: `${adminRoot}/users/user`,
        role: '/admin/users'
      },
      {
        icon: 'iconsminds-scooter',
        label: 'menu-title.delivery-boy-list',
        to: `${adminRoot}/users/delivery-boy-list`,
        role: '/admin/providers'
      },
      {
        icon: 'iconsminds-shop-2',
        label: 'menu-title.store',
        to: `${adminRoot}/users/store`,
        role: '/admin/stores'
      },
      {
        icon: 'iconsminds-administrator',
        label: 'menu-title.sub-admin',
        to: `${adminRoot}/users/sub-admin`,
        role: '/admin/sub-admin'
      }
    ],
  },
  {
    icon: 'iconsminds-ship',
    label: 'menu-title.delivery-info',
    to: `${adminRoot}/delivery-info`,
    // roles: [UserRole.Editor],
    subs: [
      {
        icon: 'iconsminds-box-close',
        label: 'menu-title.delivery',
        to: `${adminRoot}/delivery-info/delivery`,
        role: '/admin/delivery'
      },
      {
        icon: 'simple-icon-globe',
        label: 'menu-title.country-city-info',
        to: `${adminRoot}/delivery-info/country-city-info`,
        role: '/admin/country'
      },
      {
        icon: 'iconsminds-billing',
        label: 'menu-title.d-fees-info',
        to: `${adminRoot}/delivery-info/d-fees-info`,
        role: '/admin/service'
      }
    ]
  },
  {
    icon: 'iconsminds-three-arrow-fork',
    label: 'menu-title.settings',
    to: `${adminRoot}/settings`,
    // roles: [UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.basic-settings',
        to: `${adminRoot}/settings/basic-settings`,
        subs: [
          {
            icon: ' iconsminds-administrator',
            label: 'menu-title.admin',
            to: `${adminRoot}/settings/basic-settings/admin`,
            role: '/setting/basic_setting'
          },
          {
            icon: ' iconsminds-administrator',
            label: 'Accident',
            to: `${adminRoot}/settings/basic-settings/accident`,
            role: '/setting/basic_setting'
          },
          {
            icon: 'iconsminds-files',
            label: 'menu-title.document',
            to: `${adminRoot}/settings/basic-settings/document`,
            role: '/admin/document'
          },
          {
            icon: 'iconsminds-files',
            label: 'menu-title.digital-asset',
            to: `${adminRoot}/settings/basic-settings/digital-assets`,
            role: '/admin/digital-assets'
          },
          {
            icon: 'iconsminds-box-with-folders',
            label: 'menu-title.language',
            to: `${adminRoot}/settings/basic-settings/language`,
            // role: '/admin/document'
          },
          {
            icon: '',
            label: 'menu-title.dietary-and-filter-tags',
            to: `${adminRoot}/settings/basic-settings/dietary-&-filter-tags`,
            role: '/admin/dietay&filter-tags'
          }
        ],
      },
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.discount',
        to: `${adminRoot}/settings/discount`,
        subs: [
          {
            icon: 'iconsminds-information',
            label: 'menu-title.promo-code',
            to: `${adminRoot}/settings/discount/offer`,
            role: '/admin/promotions'
          },
          {
            icon: 'simple-icon-picture',
            label: 'menu-title.advertise',
            to: `${adminRoot}/settings/discount/advertise`,
            role: '/admin/advertise'
          },
          {
            icon: 'iconsminds-handshake',
            label: 'menu-title.referral-code',
            to: `${adminRoot}/settings/discount/referral`,
            role: '/admin/referral_detail'
          },
        ],
      },
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.template',
        to: `${adminRoot}/settings/template`,
        subs: [
          {
            icon: 'iconsminds-mail-settings',
            label: 'menu-title.email-settings',
            to: `${adminRoot}/settings/template/email-settings`,
            role: '/admin/email'
          },
          {
            icon: 'iconsminds-mail-settings',
            label: 'menu-title.info-settings',
            to: `${adminRoot}/settings/template/info-settings`,
            role: '/admin/info'
          },
          {
            icon: 'iconsminds-mail-settings',
            label: 'menu-title.sms-settings',
            to: `${adminRoot}/settings/template/sms-settings`,
            role: '/admin/sms'
          },
          {
            icon: 'simple-icon-screen-smartphone',
            label: 'menu-title.mass-notification',
            to: `${adminRoot}/settings/template/mass-notification`,
          }
        ],
      },
    ],
  }
];

export default data;


// import { environment } from 'src/environments/environment';
// import { UserRole } from '../shared/auth.roles';
// const adminRoot = environment.adminRoot;

// export interface IMenuItem {
//   id?: string;
//   icon?: string;
//   label: string;
//   to: string;
//   newWindow?: boolean;
//   subs?: IMenuItem[];
//   roles?: UserRole[];
// }

// const data: IMenuItem[] = [
//   {
//     icon: 'iconsminds-shop-4',
//     label: 'menu-title.dashboard',
//     to: `${adminRoot}/vien`,
//     roles: [UserRole.Admin, UserRole.Editor],
//     subs: [
//       {
//         icon: 'simple-icon-paper-plane',
//         label: 'menu-title.start',
//         to: `${adminRoot}/vien/start`,
//         // roles: [UserRole.Admin],
//       },
//     ],
//   },
//   {
//     icon: 'simple-icon-notebook',
//     label: 'menu-title.order',
//     to: `${adminRoot}`,
//     subs: [
//       {
//         icon: 'iconsminds-shopping-cart',
//         label: 'menu-title.create-order',
//         to: `${adminRoot}/create-order`
//       },
//       {
//         icon: 'simple-icon-layers',
//         label: 'menu-title.running-order',
//         to: `${adminRoot}/order`,
//         subs: [
//           {
//             icon: 'simple-icon-user-following',
//             label: 'menu-title.today-order',
//             to: `${adminRoot}/order/today-orders`,
//           },
//           {
//             icon: 'simple-icon-user-follow',
//             label: 'menu-title.tomorrow-order',
//             to: `${adminRoot}/order/tomorrow-orders`,
//           },
//           {
//             icon: 'simple-icon-user-unfollow',
//             label: 'menu-title.schedule-order',
//             to: `${adminRoot}/order/schedule-orders`,
//           },
//           {
//             icon: 'simple-icon-user-following',
//             label: 'menu-title.delivery',
//             to: `${adminRoot}/menu/levels/third-level-2`,
//           },
//           {
//             icon: 'simple-icon-user-following',
//             label: 'menu-title.dispatch',
//             to: `${adminRoot}/order/dispatch`,
//           },
//         ],
//       },
//       {
//         icon: 'simple-icon-layers',
//         label: 'menu-title.history',
//         to: `${adminRoot}/history`,
//         subs: [
//           {
//             icon: 'simple-icon-credit-card',
//             label: 'menu-title.current-week',
//             to: `${adminRoot}/history/current-week`,
//           },
//           {
//             icon: 'simple-icon-list',
//             label: 'menu-title.current-month',
//             to: `${adminRoot}/history/current-month`,
//           },
//           {
//             icon: 'simple-icon-grid',
//             label: 'menu-title.history',
//             to: `${adminRoot}/history/history-list`,
//           },
//           {
//             icon: 'simple-icon-grid',
//             label: 'menu-title.reviews',
//             to: `${adminRoot}/history/reviews`,
//           },
//           {
//             icon: 'simple-icon-picture',
//             label: 'menu-title.details',
//             to: `${adminRoot}/menu/levels/third-level-2`,
//           }
//         ],
//       },
//     ],
//   },
//   {
//     icon: 'iconsminds-air-balloon-1',
//     label: 'menu-title.earning',
//     to: `${adminRoot}/earning`,
//     subs: [
//       {
//         icon: 'iconsminds-credit-card',
//         label: 'menu-title.current-week',
//         to: `${adminRoot}/earning/current-week`,
//       }, {
//         icon: 'simple-icon-list',
//         label: 'menu-title.current-month',
//         to: `${adminRoot}/earning/current-month`,
//       }, {
//         icon: 'simple-icon-grid',
//         label: 'menu-title.earning',
//         to: `${adminRoot}/earning/earnings`,
//       }
//     ],
//   },
//   {
//     icon: 'iconsminds-pantone',
//     label: 'menu-title.menu',
//     to: `${adminRoot}/menu`,
//     subs: [
//       {
//         icon: 'simple-icon-layers',
//         label: 'menu-title.menu',
//         to: `${adminRoot}/menu/levels`,
//         subs: [
//           {
//             icon: 'simple-icon-notebook',
//             label: 'menu-title.category',
//             to: `${adminRoot}/menu/category`,
//           },
//           {
//             icon: 'simple-icon-puzzle',
//             label: 'menu-title.item',
//             to: `${adminRoot}/menu/item-list`,
//           },
//         ],
//       },
//       {
//         icon: 'simple-icon-layers',
//         label: 'menu-title.modifier',
//         to: `${adminRoot}/menu/levels`,
//         subs: [
//           {
//             icon: 'simple-icon-screen-desktop',
//             label: 'menu-title.modifier-group',
//             to: `${adminRoot}/menu/modifier-group`,
//           },
//           {
//             icon: 'simple-icon-mouse',
//             label: 'menu-title.filter',
//             to: `${adminRoot}/menu/levels/third-level-2`,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     icon: 'simple-icon-people',
//     label: 'menu-title.users',
//     to: `${adminRoot}/users`,
//     // roles: [UserRole.Editor],
//     subs: [
//       {
//         icon: 'simple-icon-user',
//         label: 'menu-title.user',
//         to: `${adminRoot}/users/user`,
//       },
//       {
//         icon: 'simple-icon-user',
//         label: 'menu-title.delivery-boy-list',
//         to: `${adminRoot}/users/delivery-boy-list`,
//       }
//     ],
//   },
//   {
//     icon: 'iconsminds-ship',
//     label: 'menu-title.delivery-info',
//     to: `${adminRoot}/delivery-info`,
//     // roles: [UserRole.Editor],
//     subs: [
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.dInfo',
//         to: `${adminRoot}/delivery-info/d-Info`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.country-city-Info',
//         to: `${adminRoot}/delivery-info/country-city-Info`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.d-fees-Info',
//         to: `${adminRoot}/delivery-info/d-fees-Info`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.promo-code',
//         to: `${adminRoot}/delivery-info/promoCode`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.advertise',
//         to: `${adminRoot}/delivery-info/advertise`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.w-history',
//         to: `${adminRoot}/delivery-info/w-history`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.w-request',
//         to: `${adminRoot}/delivery-info/w-request`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.order-list',
//         to: `${adminRoot}/delivery-info/order-list`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.deliveryman-track',
//         to: `${adminRoot}/delivery-info/deliveryman-track`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.deliveryman-location',
//         to: `${adminRoot}/delivery-info/deliveryman-location`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.store-location',
//         to: `${adminRoot}/delivery-info/store-location`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.mass-notification',
//         to: `${adminRoot}/delivery-info/mass-notification`,
//       },
//     ]
//   },
//   {
//     icon: 'iconsminds-clothing-store',
//     label: 'menu-title.sub-stores',
//     to: `${adminRoot}/sub-stores`,
//     // roles: [UserRole.Editor],
//     subs: [
//       {
//         icon: 'iconsminds-clothing-store',
//         label: 'menu-title.storeList',
//         to: `${adminRoot}/sub-stores/storeList`,
//       },
//       {
//         icon: 'simple-icon-grid',
//         label: 'menu-title.sub-stores-list',
//         to: `${adminRoot}/sub-stores/list`,
//       }
//     ]
//   },
//   {
//     icon: 'iconsminds-information',
//     label: 'menu-title.above',
//     to: `${adminRoot}/above`,
//     // roles: [UserRole.Editor],
//     subs: [
//       {
//         icon: ' iconsminds-administrator',
//         label: 'menu-title.settings',
//         to: `${adminRoot}/above/settings`,
//       },
//       {
//         icon: 'iconsminds-files',
//         label: 'menu-title.document',
//         to: `${adminRoot}/above/document`,
//       }
//     ]
//   },
//   {
//     icon: 'iconsminds-three-arrow-fork',
//     label: 'menu-title.settings',
//     to: `${adminRoot}/settings`,
//     // roles: [UserRole.Editor],
//     subs: [
//       {
//         icon: 'simple-icon-settings',
//         label: 'menu-title.store-settings',
//         to: `${adminRoot}/settings/store-settings`,
//       },
//       {
//         icon: 'simple-icon-settings',
//         label: 'menu-title.delivery-settings',
//         to: `${adminRoot}/settings/delivery-settings`,
//       },
//       {
//         icon: 'simple-icon-settings',
//         label: 'menu-title.store-open-close-settings',
//         to: `${adminRoot}/settings/store-open-close-settings`,
//       },
//       {
//         icon: 'iconsminds-mail-settings',
//         label: 'menu-title.email-settings',
//         to: `${adminRoot}/settings/email-settings`,
//       },
//       {
//         icon: 'iconsminds-mail-settings',
//         label: 'menu-title.sms-settings',
//         to: `${adminRoot}/settings/sms-settings`,
//       },
//       {
//         icon: 'simple-icon-key',
//         label: 'menu-title.google-key-settings',
//         to: `${adminRoot}/settings/google-key-settings`,
//       },
//       {
//         icon: 'simple-icon-paypal',
//         label: 'menu-title.payment-gateway-settings',
//         to: `${adminRoot}/settings/payment-gateway-settings`,
//       },
//       {
//         icon: 'iconsminds-gears',
//         label: 'menu-title.user-website-settings',
//         to: `${adminRoot}/settings/user-website-settings`,
//       },
//       {
//         icon: 'iconsminds-gears',
//         label: 'menu-title.payment',
//         to: `${adminRoot}/settings/payment`,
//       },
//     ],
//   },
//   {
//     icon: 'iconsminds-bucket',
//     label: 'menu-title.offers',
//     to: `${adminRoot}/offers`,
//     // roles: [UserRole.Editor],
//     subs: [
//       {
//         icon: 'iconsminds-financial',
//         label: 'menu-title.offer',
//         to: `${adminRoot}/offers/offer`,
//       }
//     ]
//   },
//   {
//     icon: 'iconsminds-library',
//     label: 'menu-title.profile',
//     to: `${adminRoot}/profile`,
//   },
// ];

// export default data;
